import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import React from 'react'
import { titleCase } from "title-case";
import CodeBlock from '../components/CobeBlock'
import ClickableImage from '../components/ClickableImage'

// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  Image,
  Label,
} from 'semantic-ui-react'

import HeaderBar from '../components/HeaderBar.js'
import FooterBar from '../components/FooterBar.js'

//Data
export const query = graphql
    `
    query PostsByID($id: String!) {
        mdx(
            id: { eq: $id }
        ){
            body
            rawBody
            frontmatter {
                title
                date(formatString: "YYYY MMMM Do")
                image
                topics
                format
            }
        }
    }
`


const components = { // used for syntax highlighting and other transformations
    // html element: React component to use instead
    pre: CodeBlock,
    img: ClickableImage
}

function as_html(raw_body){
    // strip off the frontmatter pre-fix and return the rest of the document as a string
    return raw_body.substring(raw_body.indexOf("---", raw_body.indexOf("---")+3)+3);
}

var BlogPostMDX = ({ data }) => {
    const { frontmatter, body, rawBody } = data.mdx
    return (
<div>
    <header role="banner">
        <HeaderBar />
    </header>
    <main role="main">

        <Container text style={{ marginTop: '7em' }}>
            <div>
                <p>{frontmatter.topics.map((t) => (<Label color='teal'>{titleCase(t)}</Label>))}</p>
                <table border={'0'} style={{padding: 4}}><tr><td>
                    <Image src={"/"+frontmatter.image} size='small' />
                </td><td>
                    <h1>{frontmatter.title}</h1>
                    <p>{frontmatter.date}</p>
                </td></tr></table>
            </div>
            <div id="blogContent" class="blogContent">
                {(() => {
                    if (frontmatter.format === "mdx") {
                        return (<MDXProvider components={components}><MDXRenderer>{body}</MDXRenderer></MDXProvider>);
                    } else if(frontmatter.format === "xhtml"){
                        // NOTE: html content must be XML compliant (no unterminated tags)
                        return (<div dangerouslySetInnerHTML={{__html: as_html(rawBody)}} />);
                    } else {
                        return (<span>"ERROR: Unsupported blog post format "{frontmatter.format}</span>);
                    }
                })()}
            </div>
        </Container>


    </main>
    <footer role="banner">
        <FooterBar />
    </footer>
</div>
    )
}

export default BlogPostMDX;