import React from 'react'
import { 
    Image,
} from 'semantic-ui-react'

var ClickableImage = (props) => {
    return (
        <Image alt={props.alt} src={props.src} href={props.src} size={sizeFromURL(props.src, "large")} inline />
    )
}

function sizeFromURL(url_str, default_size){
    if(url_str.indexOf('?') < 0){
        return default_size;
    }
    if(url_str.indexOf('http') != 0){
        // need to prefix http or we can't make a URL
        url_str = 'http://'+url_str;
    }
    const queryParams = (new URL(url_str)).searchParams;
    var size = queryParams.get("size");
    if(size != null){
        return size;
    } else {
        return default_size;
    }
}


export default ClickableImage;
